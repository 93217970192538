import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';

import Footer from './Footer';
import Loading from 'view/layout/Loading';
import Menu from './Menu';
import RoutesComponent from './Route';
import * as URoute from 'utils/Route';
import Analytics from 'utils/Analytics';
import { Params } from 'config/Params';

import css from 'static/css/app.module.css';

class Layout extends Component {
    componentDidMount() {
        URoute.set({ loading: true });
        URoute.set({ loading: false });
    }

    render() {
        return (
            <React.Fragment>
                {!this.props.loading && this.renderLoaded()}
                <Loading loading={this.props.loading} />
            </React.Fragment>
        );
    }

    renderLoaded() {
        const { title, desc, canonical } = URoute.get();

        return (
            <Suspense fallback={<Loading bg={true} />}>
                <React.Fragment>
                    <Helmet>
                        <title>{title}</title>
                        <meta name='description' content={desc} />
                        <link rel='canonical' href={'https://www.animitemedia.com' + canonical} />
                    </Helmet>
                    <div className={css.wrapSite}>
                        <Router>
                            <Analytics trackingId={Params.google.analytics}>
                                <Menu />
                                <RoutesComponent />
                                {/* <Footer /> */}
                            </Analytics>
                        </Router>
                    </div>
                </React.Fragment>
            </Suspense>
        );
    }
}

Layout.propTypes = {
    auth: PropTypes.object,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.user,
    title: state.route.title,
    loading: state.route.loading,
});

export default connect(mapStateToProps)(Layout);
