import React, { Component, lazy } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as URoute from 'utils/Route';

// const AboutPage = withRouter(lazy(() => import('view/page/site/About')));
// const ContactPage = withRouter(lazy(() => import('view/page/site/Contact')));
// const ErrorPage = withRouter(lazy(() => import('view/page/site/Error')));
// const HomePage = withRouter(lazy(() => import('view/page/site/Home')));
// const PolicyPage = withRouter(lazy(() => import('view/page/site/Policy')));
// const ServicesPage = withRouter(lazy(() => import('view/page/site/Services')));

// const CookiePage = withRouter(lazy(() => import('view/page/policy/Cookie')));
// const PrivacyPage = withRouter(lazy(() => import('view/page/policy/Privacy')));
// const TermsPage = withRouter(lazy(() => import('view/page/policy/Terms')));

// const AuditPage = withRouter(lazy(() => import('view/page/services/Audit')));
// const MaintenancePage = withRouter(lazy(() => import('view/page/services/Maintenance')));
// const SoftDevPage = withRouter(lazy(() => import('view/page/services/SoftDev')));
// const WebDevPage = withRouter(lazy(() => import('view/page/services/WebDev')));

const OfflinePage = withRouter(lazy(() => import('view/page/site/Offline')));

class RoutesComponent extends Component {
    componentDidMount() {
        if (URoute.get().current !== '' && URoute.get().current !== this.props.location.pathname) {
            //URoute.set({ redirect: URoute.get().current });
        }
        this.routeLogActions();
    }

    componentDidUpdate() {
        this.routeLogActions();
    }

    routeLogActions = async () => {
        if (this.props.location.pathname !== URoute.get().current) {
            URoute.set({
                current: this.props.location.pathname,
                loading: true,
            });
            URoute.addLog(this.props.location.pathname);
            setTimeout(() => {
                if (URoute.get().loading) {
                    URoute.set({ loading: false });
                }
            }, 100);
        } else if (URoute.get().redirect !== '') {
            this.props.history.push(URoute.get().redirect);
            URoute.set({
                current: URoute.get().redirect,
                redirect: '',
                loading: true,
            });
            setTimeout(() => {
                if (URoute.get().loading) {
                    URoute.set({ loading: false });
                }
            }, 100);
        }
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <React.Fragment>
                <Switch>
                    {/* <Route path='/' exact component={HomePage} />
                    <Route path='/about' component={AboutPage} />
                    <Route path='/contact' component={ContactPage} />

                    <Route path='/policy' exact component={PolicyPage} />
                    <Route path='/policy/cookies' component={CookiePage} />
                    <Route path='/policy/privacy' component={PrivacyPage} />
                    <Route path='/policy/terms' component={TermsPage} />

                    <Route path='/services' exact component={ServicesPage} />
                    <Route path='/services/application-maintenance' component={MaintenancePage} />
                    <Route path='/services/software-development' component={SoftDevPage} />
                    <Route path='/services/technology-audit' component={AuditPage} />
                    <Route path='/services/web-development' component={WebDevPage} />

                    <Route component={ErrorPage} /> */}
                    <Route component={OfflinePage} />
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    route: state.route,
});

export default withRouter(connect(mapStateToProps)(RoutesComponent));
