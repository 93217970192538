import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import css from 'static/css/app.module.css';

class Footer extends Component {
    render() {
        return (
            <footer className={css.footer}>
                <Container>
                    <Grid container alignItems='stretch'>
                        <Grid item xs={12} sm={6} md={3} className={css.footerItems}>
                            <p className={css.footerTitle}>About</p>
                            <List>
                                <ListItem
                                    button
                                    className={css.item}
                                    component={Link}
                                    to='/services'>
                                    <ListItemText primary='Our Services' />
                                </ListItem>

                                <ListItem button className={css.item} component={Link} to='/about'>
                                    <ListItemText primary='About us' />
                                </ListItem>

                                <ListItem button className={css.item} component={Link} to='/policy'>
                                    <ListItemText primary='Our Policies' />
                                </ListItem>

                                <ListItem
                                    button
                                    className={css.item}
                                    component={Link}
                                    to='/contact'>
                                    <ListItemText primary='Contact us' />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} className={css.footerItems}>
                            <p className={css.footerTitle}>Services</p>
                            <List>
                                <ListItem
                                    button
                                    className={css.item}
                                    component={Link}
                                    to='/services/web-development'>
                                    <ListItemText primary='Web Development' />
                                </ListItem>
                                <ListItem
                                    button
                                    className={css.item}
                                    component={Link}
                                    to='/services/software-development'>
                                    <ListItemText primary='Software Development' />
                                </ListItem>
                                <ListItem
                                    button
                                    className={css.item}
                                    component={Link}
                                    to='/services/technology-audit'>
                                    <ListItemText primary='Technology Audit' />
                                </ListItem>
                                <ListItem
                                    button
                                    className={css.item}
                                    component={Link}
                                    to='/services/application-maintenance'>
                                    <ListItemText primary='Application Maintenance' />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} md={6} className={css.footerLogo}>
                            <Link to='/'>
                                <img src='/img/logo/logo-li-md.png' alt='Animite Media Logo' />
                            </Link>
                        </Grid>
                    </Grid>
                    <div className={css.sep} />
                    <p className={css.copyright}>
                        &copy; Animite Media {new Date().getFullYear()}, All Rights Reserved.
                    </p>
                </Container>
            </footer>
        );
    }
}

export default Footer;
