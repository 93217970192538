import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';

import * as URoute from 'utils/Route';

import css from 'static/css/app.module.css';

class Menu extends Component {
    state = {
        open: false,
    };

    items = [
        // {
        //     name: 'Home',
        //     url: '/',
        // },
        // {
        //     name: 'Services',
        //     url: '/services',
        // },
        // {
        //     name: 'About',
        //     url: '/about',
        // },
        // {
        //     name: 'Contact',
        //     url: '/contact',
        // },
    ];

    onToggleMenu = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { open } = this.state;
        const { current } = URoute.get();

        let float = false;
        if (current === '/') {
            float = true;
        }

        return (
            <header className={classnames([css.menu, float && css.float, open && css.active])}>
                <Container>
                    <Grid container alignItems='center'>
                        <Grid item xs={8} md className={css.menuLogo}>
                            <div className={css.menuLogoInner}>
                                <Link to='/'>
                                    <img src='/img/logo/logo-dk-sm.png' alt='Animite Media Logo' />
                                </Link>
                            </div>
                        </Grid>
                        <Grid item xs={4} className={css.menuToggle}>
                            <IconButton
                                edge='end'
                                color={open ? 'secondary' : 'inherit'}
                                aria-label='menu'
                                onClick={this.onToggleMenu}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} md className={css.menuItems}>
                            <Grid
                                container
                                direction='row'
                                justify='flex-end'
                                alignItems='center'
                                spacing={3}>
                                {this.items.map((item) => (
                                    <Grid item key={item.name}>
                                        <Button
                                            className={classnames([
                                                css.item,
                                                current === item.url && css.active,
                                            ])}
                                            component={Link}
                                            to={item.url}
                                            onClick={this.onToggleMenu}>
                                            {item.name}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <div className={css.sidebar}>
                    <List>
                        {this.items.map((item) => (
                            <ListItem
                                key={item.name}
                                button
                                className={classnames([
                                    css.item,
                                    current === item.url && css.active,
                                ])}
                                component={Link}
                                to={item.url}
                                onClick={this.onToggleMenu}>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </header>
        );
    }
}

export default Menu;
