import React, { Component } from 'react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

import Layout from 'router/Layout';

import css from 'static/css/app.module.css';
import 'static/css/vendor.css';

const theme = createMuiTheme({
    palette: {
        //contrastThreshold: 2,
        //type: 'dark',
        primary: blue,
        secondary: orange,
    },
    typography: {
        fontFamily: ['Raleway', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    },
});

class App extends Component {
    render() {
        return (
            <div>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className={css['wrap-site']}>
                        <Layout />
                    </div>
                </MuiThemeProvider>
            </div>
        );
    }
}

export default App;
