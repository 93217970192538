const Params = {
    company: {
        name: 'Animite Media',
    },
    particleJs: {
        particles: {
            number: { value: 52, density: { enable: true, value_area: 800 } },
            color: { value: '#ffffff' },
            shape: {
                type: 'circle',
                stroke: { width: 0, color: '#000000' },
                polygon: { nb_sides: 5 },
                image: { src: 'img/github.svg', width: 100, height: 100 },
            },
            opacity: {
                value: 0.24463576890600452,
                random: false,
                anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
                value: 3,
                random: true,
                anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
            },
            line_linked: { enable: true, distance: 150, color: '#ffffff', opacity: 0.4, width: 1 },
            move: {
                enable: true,
                speed: 6,
                direction: 'none',
                random: true,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
        },
        interactivity: {
            detect_on: 'canvas',
            events: {
                onhover: { enable: true, mode: 'grab' },
                onclick: { enable: true, mode: 'push' },
                resize: true,
            },
            modes: {
                grab: { distance: 400, line_linked: { opacity: 1 } },
                bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
                repulse: { distance: 200, duration: 0.4 },
                push: { particles_nb: 4 },
                remove: { particles_nb: 2 },
            },
        },
        retina_detect: true,
    },
    google: {
        apiKey: 'AIzaSyCEb-6tDkCZot5kbh59dKgjvTknbn0kij8',
        analytics: 'UA-6903550-2',
        recaptcha: {
            publicKey: '6LdOZzkUAAAAADhJx7XpldqTzBB5HpkJuuWa0mrv',
        },
    },
    url: {
        api: 'https://www.animitemedia.com/api/',
        img: 'https://www.animitemedia.com/img/',
    },
};

if (process.env.NODE_ENV === 'development') {
    Params.google.apiKey = 'AIzaSyCJzzCI2jXQrIENxgv2PEXErdbJIlxdzHY';
    Params.google.analytics = '';
    Params.url.api = 'http://localhost/animite/api/';
    Params.url.img = 'http://localhost/animite/img/';
    Params.google.recaptcha.publicKey = '6LfPcuMSAAAAANNlpHenOty0vFfFI8PlvIR5agBX';
}

export { Params };
