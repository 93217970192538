import { CLEAR_ROUTE, SET_ROUTE } from 'store/action/types';
import * as R from 'ramda';

const initialState = {
    back: '',
    current: '',
    redirect: '',
    title: 'Animite Media | Web & Software Development - Scotland',
    desc:
        'Animite Media is an Scottish web development company that builds enterprise-grade, customised websites, web applications and web portals for businesses.',
    canonical: 'https://www.animitemedia.com/',
    log: [],
    loading: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ROUTE:
            return R.clone(initialState);
        case SET_ROUTE:
            return R.mergeDeepRight(state, action.payload);
        default:
            return state;
    }
}
